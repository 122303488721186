import TextRotator from "../molecules/TextRotator";

export default function HeroRotator() {
  const items = [
    "prioritize methodically?",
    "know what your customers want?",
    "know which metric to optimize?",
    "understand the business drivers?",
    "test alternative designs?",
    "build solid business cases?",
    "agree on the details of complex processes?",
  ];

  return (
    <>
      <section className="pt-24 pb-48 lg:pt-20">
        <div className="widthcontainer mx-auto text-center p-4 lg:p-4 items-center">
          <h1 className="px-8 py-4 text-5xl md:text-6xl lg:text-8xl italic characterfont">Does your team ...</h1>
          <h2>
            <div className="px-8 py-4  text-3xl md:text-4xl lg:text-6xl">
              <TextRotator speed={4000} header="Does your team ..." items={items} />
            </div>
          </h2>
        </div>
      </section>
    </>
  );
}
