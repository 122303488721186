import { select, selectAll, arc, pie, interpolate } from "d3";
import { useRef, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import "./DoubleSidedBarChart.css";
import { Waypoint } from "react-waypoint";
import "./PieChart.css";

export default function PieChart({ data, arcEndsLabels, title }) {
  const [zeroer, setZeroer] = useState(0);
  const svgRef = useRef(null);
  const config = {
    y_margin: 36,
    x_margin_ratio: 0.1,
    title: { gutter: 15 },
    lowerlabels: { height: 20, gutter: 10 },
    pie: { maxRadius: 100, thicknessratio: 0.5 },
  };

  const layoutbase = {
    layoutSet: false,
    svgGroups: {
      titleSvgG: { ref: useRef(null), color: "red" },
      piePlotDummySvgG: { ref: useRef(null), color: "green" },
      piePlotPinpointSvgG: { ref: useRef(null), color: "green", width: 10, height: 10 },
      lowerLabelsSvgG: { ref: useRef(null), color: "blue" },
    },
  };
  const [layout, setLayout] = useState(layoutbase);
  const chartStartAngle = -Math.PI;
  function repositionSvgGroups() {
    for (const [key_layoutAreaName, value_layoutAreaDef] of Object.entries(layout.svgGroups)) {
      select(value_layoutAreaDef.ref.current).attr(
        "transform",
        `translate(${value_layoutAreaDef.x}, ${value_layoutAreaDef.y})`
      );
    }
  }
  function colorSvgGroups() {
    selectAll(".debugshading_pie").remove();
    for (const [layoutAreaName, layoutAreaDef] of Object.entries(layout.svgGroups)) {
      if (layoutAreaDef.width == 1 && layoutAreaDef.height == 1) {
        // Point Group
        select(layoutAreaDef.ref.current)
          .append("line")
          .style("stroke", "red")
          .style("stroke-width", 1)
          .attr("x1", -6)
          .attr("x2", 6)
          .attr("y1", 0)
          .attr("y2", 0);
        select(layoutAreaDef.ref.current)
          .append("line")
          .style("stroke", "red")
          .style("stroke-width", 1)
          .attr("x1", 0)
          .attr("x2", 0)
          .attr("y1", -6)
          .attr("y2", 6);
      } else {
        // Area Group

        select(layoutAreaDef.ref.current)
          .append("rect")
          .attr("class", "debugshading_pie svggroup_" + layoutAreaName)
          .attr("width", layoutAreaDef.width)
          .attr("height", layoutAreaDef.height)
          .attr("fill", layoutAreaDef.color)
          .style("opacity", 0.5);
      }
    }
  }
  function updateLayout() {
    const containerwidth = svgRef.current.clientWidth;
    const containerheight = svgRef.current.clientHeight;

    const newLayout = { layoutSet: true, pie: {}, svgGroups: {} };

    // Margin
    newLayout.xMargin = containerwidth * config.x_margin_ratio;

    // Pie Radii
    newLayout.pie.outerRadius = Math.min(config.pie.maxRadius, containerwidth / 2 - newLayout.xMargin);
    newLayout.pie.innerRadius = newLayout.pie.outerRadius - newLayout.pie.outerRadius * config.pie.thicknessratio;
    newLayout.pie.midRadius = newLayout.pie.innerRadius + newLayout.pie.outerRadius / 2 - newLayout.pie.innerRadius / 2;

    // Title SVG Group
    newLayout.svgGroups.titleSvgG = {
      ...layout.svgGroups.titleSvgG,
      // x: newLayout.xMargin,
      x: containerwidth / 2 - newLayout.pie.outerRadius,
      y: config.y_margin,
      // width: containerwidth - 2 * newLayout.xMargin,
      width: 2 * newLayout.pie.outerRadius,
      height: 20,
    };

    // Pie Center SVG Group - THE ONE JUST FOR DEBUG
    newLayout.svgGroups.piePlotDummySvgG = {
      ...layout.svgGroups.piePlotDummySvgG,
      x: newLayout.svgGroups.titleSvgG.x,
      y: newLayout.svgGroups.titleSvgG.y + newLayout.svgGroups.titleSvgG.height + config.title.gutter,
      width: newLayout.svgGroups.titleSvgG.width,
      height: newLayout.pie.outerRadius,
    };

    // Pie Center SVG Group - THE ONE USED
    newLayout.svgGroups.piePlotPinpointSvgG = {
      ...layout.svgGroups.piePlotPinpointSvgG,
      x: containerwidth / 2,
      y: newLayout.svgGroups.piePlotDummySvgG.y + newLayout.svgGroups.piePlotDummySvgG.height,
      width: 1,
      height: 1,
    };

    // Lower Labels SVG Group
    newLayout.svgGroups.lowerLabelsSvgG = {
      ...layout.svgGroups.lowerLabelsSvgG,
      x: newLayout.svgGroups.titleSvgG.x,
      y: newLayout.svgGroups.piePlotPinpointSvgG.y + config.lowerlabels.gutter,
      width: newLayout.svgGroups.titleSvgG.width,
      height: config.lowerlabels.height,
    };
    setLayout((layout) => ({ ...layout, ...newLayout }));
  }

  function drawChart() {
    let my_pieGenerator = pie()
      .value((d) => d.value)
      .startAngle(0)
      .endAngle(Math.PI)
      .sort(null);

    let my_arc = arc().innerRadius(layout.pie.innerRadius).outerRadius(layout.pie.outerRadius);

    let my_pie_arc_data = my_pieGenerator(data);

    var cumul_angle_size_to_prior_end = 0;

    my_pie_arc_data.forEach((curr) => {
      let angleSize = curr.endAngle - curr.startAngle;
      curr.midAngle = curr.startAngle + angleSize / 2;
      // Interpolators
      curr.zeroToAngleSizeInt = interpolate(0, angleSize);
      curr.zeroToCumAngleSizeToPriorEndInt = interpolate(0, cumul_angle_size_to_prior_end);
      // Cumulator
      cumul_angle_size_to_prior_end += angleSize;
    });
    let my_pie_arcs = select(layout.svgGroups.piePlotPinpointSvgG.ref.current)
      .selectAll(".donut-chart-arc")
      .data(my_pie_arc_data);
    my_pie_arcs
      .enter()
      .append("path")
      .attr("class", (d, i) => "donut-chart-arc donut-chart-segment-" + i)
      .attr("d", (d) => my_arc({ startAngle: 0, endAngle: 0 }))
      .attr("fill", "#37789e") //(d) => d.data.color)
      .attr("opacity", (d, i) => 1 - i * 0.2);

    my_pie_arcs
      .merge(my_pie_arcs)
      .transition()
      .duration(1000)
      .attrTween("d", (d, i) => {
        return (t) => {
          d.startAngle = -Math.PI / 2 + d.zeroToCumAngleSizeToPriorEndInt(t);
          d.endAngle = d.startAngle + d.zeroToAngleSizeInt(t);
          return my_arc(d);
        };
      });

    /////// TITLE
    const pietitle = select(layout.svgGroups.titleSvgG.ref.current).selectAll(".pietitle").data([title]);
    pietitle
      .enter()
      .append("text")
      .attr("class", "pietitle")
      .text((d) => d);
    pietitle.merge(pietitle).attr("x", 0).attr("y", 0);
    pietitle.exit().remove();

    const pielabelleft = select(layout.svgGroups.piePlotPinpointSvgG.ref.current)
      .selectAll(".pielabelleft")
      .data([arcEndsLabels.left]);
    pielabelleft
      .enter()
      .append("text")
      .attr("class", "pielabelleft")
      .text((d) => d);
    pielabelleft.merge(pielabelleft).attr("x", -layout.pie.outerRadius).attr("y", config.lowerlabels.gutter);
    pielabelleft.exit().remove();

    const pielabelright = select(layout.svgGroups.piePlotPinpointSvgG.ref.current)
      .selectAll(".pielabelright")
      .data([arcEndsLabels.right]);
    pielabelright
      .enter()
      .append("text")
      .attr("class", "  pielabelright")
      .text((d) => d);
    pielabelright.merge(pielabelright).attr("x", layout.pie.outerRadius).attr("y", config.lowerlabels.gutter);

    pielabelright.exit().remove();

    function getXforAngle(startAngle, angle, radius) {
      return radius * Math.cos(startAngle + angle);
    }
    function getYforAngle(startAngle, angle, radius) {
      return radius * Math.sin(startAngle + angle);
    }

    const piepercentages = select(layout.svgGroups.piePlotPinpointSvgG.ref.current)
      .selectAll(".piepercentages")
      .data(my_pie_arc_data);
    piepercentages
      .enter()
      .append("text")
      .attr("class", "piepercentages")
      .text((d) => d.data.value + "%");

    piepercentages
      .merge(piepercentages)
      .transition()
      .duration(10)
      .attr("opacity", 0)
      .attr("x", (d, i) => getXforAngle(chartStartAngle, d.midAngle, layout.pie.midRadius))
      .attr("y", (d, i) => getYforAngle(chartStartAngle, d.midAngle, layout.pie.midRadius))
      .transition()
      .delay(1000)
      .duration(1000)
      .attr("opacity", 1);

    piepercentages.exit().remove();
  }

  useEffect(() => {
    updateLayout();
    const handleResize = debounce(() => {
      updateLayout();
    }, 400);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (layout.layoutSet) {
      repositionSvgGroups();
      drawChart();
      // colorSvgGroups();
    }
  }, [layout]);

  useEffect(() => {
    if (layout.layoutSet) {
      drawChart();
    }
  }, [zeroer]); // this also triggered on 'data' changes, but it was triggering on scroll so took it out for now

  return (
    <Waypoint bottomOffset="30%" onEnter={() => setZeroer(1)}>
      <svg ref={svgRef} style={{ width: "100%", height: "100%" }}>
        <g ref={layout.svgGroups.titleSvgG.ref} />
        <g ref={layout.svgGroups.piePlotPinpointSvgG.ref} />
        <g ref={layout.svgGroups.piePlotDummySvgG.ref} />
        <g ref={layout.svgGroups.lowerLabelsSvgG.ref} />
      </svg>
    </Waypoint>
  );
}
