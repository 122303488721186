export default function H1H2Block({ h1text, h2text }) {
  return (
    <section className="py-16 ">
      <div className="p-8 md:p-36">
        <div className="widthcontainer mx-auto text-center text-slate-800 ">
          <h1 className="text-5xl">{h1text}</h1>
          <p className="text-xl m-4">{h2text}</p>
          <div className="mt-12">
            <a className="" target="new" href="mailto:info@manwomanandchild.com">
              tom@manwomanandchild.com
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
