import DoubleSidedBarChart from "../charts/DoubleSidedBarChart";
import RangeSliderChart from "../charts/RangeSliderChart";
import { Waypoint } from "react-waypoint";
import React, { useState } from "react";
import PieChart from "../charts/PieChart";

export default function ChartsPanel() {
  const [isVisible, setIsVisible] = useState([false, false, false]);
  const handleEnterViewport = (index) => {
    setIsVisible((prevVisible) => {
      const newState = [...prevVisible];
      newState[index] = true;
      return newState;
    });
  };
  const rangeslidermultidataset = [
    {
      dataset: "Small Businesses",
      range: [0, 10],
      values: [
        { category: "<4", low: 2.2, median: 4.2, high: 5.2 },
        { category: "4-6", low: 3.4, median: 5.0, high: 5.8 },
        { category: "6-8", low: 3.7, median: 6.0, high: 7.9 },
        { category: "8-10", low: 4.2, median: 7.3, high: 8.9 },
        { category: "10-14", low: 4.8, median: 6.5, high: 8.2 },
        { category: "14-18", low: 6.2, median: 7, high: 10 },
        { category: ">18", low: 5.5, median: 7.1, high: 9.2 },
      ],
    },
    {
      dataset: "Large Businesses",
      range: [0, 10],
      values: [
        { category: "<30", low: 5, median: 15, high: 20 },
        { category: "30-39", low: 20, median: 35, high: 40 },
        { category: "40-49", low: 50, median: 55, high: 60 },
      ],
    },
  ];
  const bandedbarmultidataset = [
    {
      dataset: "Small Businesses",
      bands: ["Lost", "20%", "40%", "60%", "80%", "Won"],
      values: [
        { category: "<$10,000", bandvalues: [20, 50, 80, 40, 30, 20] },
        { category: "Up to $100,000", bandvalues: [20, 50, 80, 40, 30, 20] },
        { category: "Up to $200,000", bandvalues: [20, 50, 80, 40, 30, 20] },
        { category: "Up to $300,000", bandvalues: [20, 50, 80, 40, 30, 20] },
        { category: "Up to $400,000", bandvalues: [20, 50, 80, 40, 30, 20] },
        { category: ">$500,000", bandvalues: [40, 45, 50, 40, 30, 20] },
      ],
    },
  ];
  const doubleSidedBarData = [
    {
      dataset: "Small Businesses",
      bands: ["None", "Basic", "Conversational", "Fluent", "Native/Bilingual"],
      sides: {
        left: {
          name: "English",
          values: [0.5, 0.9, 7, 37, 55],
        },
        right: {
          name: "Japanese",
          values: [5, 25, 46, 23, 5],
        },
      },
    },
    {
      dataset: "Large Businesses",
    },
  ];
  const doubleSidedBarData2 = [
    {
      dataset: "Small Businesses",
      bands: ["<25", "26-29", "30-39", "40-49", "50-59", "60-69", "70"],
      sides: {
        left: {
          name: "Women",
          values: [30, 20, 50, 80, 60, 50, 50],
        },
        right: {
          name: "Men",
          values: [20, 40, 25, 80, 60, 30, 50],
        },
      },
    },
    {
      dataset: "Large Businesses",
    },
  ];

  const piedata_jap = [
    { key: 0, value: 64, color: "#ff33aa" },
    { key: 1, value: 15, color: "#11aaff" },
    { key: 2, value: 21, color: "#33ff33" },
  ];
  const piedata_for = [
    { key: 0, value: 17, color: "#ff33aa" },
    { key: 1, value: 45, color: "#11aaff" },
    { key: 2, value: 38, color: "#33ff33" },
  ];
  const subtext = [
    "There are two challenges I often see companies facing. One is a lack of any data or certain types of data. There are often some easy wins available.",
    "The other challenge is having good data but struggling to extract actionable insights. Again there may be some easy wins, but usually it's a bigger team effort even requiring culture change.",
  ];
  return (
    <section className={"pt-20 pb-20 "}>
      <div className="widthcontainer mx-auto text-center ">
        {/* HEADERSUBTEXT
        <Waypoint bottomOffset="20%" onEnter={() => handleEnterViewport(0)} onLeave={() => handleExitViewport(0)}>
          <div className={`w-full transition duration-500   ${isVisible[0] ? "opacity-100" : "opacity-0"}`}>
            <h1 className="mt-12 mb-4">{heading}</h1>
          </div>
        </Waypoint>

        SUBTEXT
        <Waypoint bottomOffset="20%" onEnter={() => handleEnterViewport(1)} onLeave={() => handleExitViewport(0)}>
          <div className={`w-full transition duration-500   ${isVisible[1] ? "opacity-100" : "opacity-0"}`}>
            <div>
              {subtext.map((para, index) => {
                return (
                  <p className="text-xl mt-8 mb-8 text-left" key={index}>
                    {para}
                  </p>
                );
              })}
            </div>
          </div>
        </Waypoint>
      </div>
      IMAGE
      <Waypoint bottomOffset="20%" onEnter={() => handleEnterViewport(2)} onLeave={() => handleExitViewport(0)}>
        <div className={`w-full transition duration-500   ${isVisible[2] ? "opacity-100" : "opacity-0"}`}>
          <div className="widthcontainer mx-auto">
            <img className="shadow-lg mx-auto" src={image} alt={alttext} />
          </div>
        </div>
      </Waypoint>   */}
        <Waypoint bottomOffset="20%" onEnter={() => handleEnterViewport(0)}>
          <div className={`w-full transition duration-500   ${isVisible[0] ? "opacity-100" : "opacity-0"}`}>
            <h1 className="mt-12 mb-4">My work with data</h1>
          </div>
        </Waypoint>

        <Waypoint bottomOffset="20%" onEnter={() => handleEnterViewport(1)}>
          <div className={`w-full transition duration-500   ${isVisible[1] ? "opacity-100" : "opacity-0"}`}>
            <div>
              {subtext.map((para, index) => {
                return (
                  <p className="text-xl mt-8 mb-8 text-left" key={index}>
                    {para}
                  </p>
                );
              })}
            </div>
          </div>
        </Waypoint>

        <div className=" w-full text-center drop-shadow-xl pb-16 bg-white">
          <div className="widthcontainer flex flex-col md:flex-row">
            <div className="flex-1 p4 sm:p-12">
              <RangeSliderChart multidataset={rangeslidermultidataset} />
            </div>
            <div className="flex-1 p4 sm:p-12">
              <DoubleSidedBarChart data={doubleSidedBarData2} centreLabelsWidth={70} />
            </div>
          </div>
          <div className="widthcontainer flex flex-col md:flex-row  gap-4">
            <div className="w-full md:w-1/2 p-4 h-64">
              <DoubleSidedBarChart data={doubleSidedBarData} centreLabelsWidth={140} />
            </div>
            <div className="w-full md:w-1/2 p-4 ">
              <div className="flex flex-col sm:flex-row">
                <div className="w-full sm:w-1/2 h-56">
                  <PieChart
                    data={piedata_jap}
                    title="Japanese-owned"
                    arcEndsLabels={{ left: "Japanese", right: "English" }}
                  />
                </div>
                <div className="w-full sm:w-1/2 h-56">
                  <PieChart
                    data={piedata_for}
                    title="Foreign-owned"
                    arcEndsLabels={{ left: "Japanese", right: "English" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
