import { select, max, selectAll } from "d3";
import { useRef, useEffect, useState, useLayoutEffect } from "react";
import debounce from "lodash/debounce";
import "./DoubleSidedBarChart.css";
import { Waypoint } from "react-waypoint";
import merge from "lodash.merge";

// https://stackoverflow.com/questions/64034706/responsive-d3-charts-in-react-resizeobserver-or-the-viewbox-attribute

// const doubleSidedBarData = [
//   {
//     dataset: "Small Businesses",
//     bands: ["<25", "26-29", "30-39", "40-49", "50-59", "60-69", "70"],
//     sides: {
//       left: {
//         name: "Women",
//         values: [80, 80, 85, 80, 60, 50, 50],
//       },
//       right: {
//         name: "Men",
//         values: [20, 80, 25, 80, 60, 50, 50],
//       },
//     },
//   },
//   {
//     dataset: "Large Businesses",
//   },
// ];

// data[dataSetIndex].bands
// data[dataSetIndex].sides
// data[dataSetIndex].sides.left
// data[dataSetIndex].sides.left.name
// data[dataSetIndex].sides.left.values
// data[dataSetIndex].sides.right
// data[dataSetIndex].sides.right.name
// data[dataSetIndex].sides.right.values

export default function DoubleSidedBarChart({ data, centreLabelsWidth }) {
  const [dataSetIndex, setDataSetIndex] = useState(0);
  const [zeroer, setZeroer] = useState(0);
  const config = {
    margin: 20,
    centreLabels: { width: centreLabelsWidth },
    header: {
      height: 30,
      gutterBelow: 20,
    },
    barsarea: {
      labels: { spacereserved: 40, gaptobar: 5 },
      bars: { height: 20, yspacing: 10 },
    },
    timing: {
      growduration: 500,
      delayspace: 100,
    },
  };
  // config.centreLabels.width = centreLabelsWidth;

  const svgContainerDivRef = useRef(null);
  const svgRef = useRef(null);
  const layoutbase = {
    headerLeft: { ref: useRef(null), color: "blue" },
    headerRight: { ref: useRef(null), color: "red" },
    plotLeft: { ref: useRef(null), color: "green" },
    plotRight: { ref: useRef(null), color: "orange" },
    centre: { ref: useRef(null), color: "pink" },
  };
  const [layout, setLayout] = useState(layoutbase);

  function updateLayout() {
    const containerwidth = svgContainerDivRef.current.clientWidth;
    const layoutnew = {};
    layoutnew.headerLeft = {};
    layoutnew.headerLeft.x = config.margin;
    layoutnew.headerLeft.y = config.margin;
    layoutnew.headerLeft.width = (containerwidth - config.centreLabels.width - config.margin * 2) / 2;
    layoutnew.headerLeft.height = config.header.height;
    layoutnew.headerRight = {};
    layoutnew.headerRight.x = config.margin + config.centreLabels.width + layoutnew.headerLeft.width;
    layoutnew.headerRight.y = layoutnew.headerLeft.y;
    layoutnew.headerRight.width = layoutnew.headerLeft.width;
    layoutnew.headerRight.height = config.header.height;
    layoutnew.plotLeft = {};
    layoutnew.plotLeft.x = config.margin;
    layoutnew.plotLeft.y = config.margin + config.header.gutterBelow + layoutnew.headerLeft.height;
    layoutnew.plotLeft.width = layoutnew.headerLeft.width;
    layoutnew.plotLeft.height = 90;
    layoutnew.plotRight = {};
    layoutnew.plotRight.x = layoutnew.headerRight.x;
    layoutnew.plotRight.y = layoutnew.plotLeft.y;
    layoutnew.plotRight.width = layoutnew.plotLeft.width;
    layoutnew.plotRight.height = layoutnew.plotLeft.height;
    layoutnew.centre = {};
    layoutnew.centre.x = config.margin + layoutnew.plotLeft.width;
    layoutnew.centre.y = layoutnew.plotLeft.y;
    layoutnew.centre.width = config.centreLabels.width;
    layoutnew.centre.height = layoutnew.plotLeft.height;
    const newmergedlayout = merge(layout, layoutnew);
    // setLayout((layout) => merge(layout, layoutnew)); // doesn't update it
    setLayout((layout) => ({ ...layout, ...newmergedlayout }));
  }

  function repositionSvgGroups() {
    for (const [key_layoutAreaName, value_layoutAreaDef] of Object.entries(layout)) {
      select(value_layoutAreaDef.ref.current).attr(
        "transform",
        `translate(${value_layoutAreaDef.x}, ${value_layoutAreaDef.y})`
      );
    }
  }
  function colorSvgGroups() {
    selectAll(".debugshading").remove();
    for (const [layoutAreaName, layoutAreaDef] of Object.entries(layout)) {
      select(layoutAreaDef.ref.current)
        .append("rect")
        .attr("class", "debugshading svggroup_" + layoutAreaName)
        .attr("width", layoutAreaDef.width)
        .attr("height", layoutAreaDef.height)
        .attr("fill", layoutAreaDef.color)
        .style("opacity", 0.1);
    }
  }
  function drawChart() {
    const barXScaler =
      (layout.plotLeft.width - config.barsarea.labels.spacereserved) / max(data[dataSetIndex].sides.left.values);

    // const headerlhslabel = select(layout.headerLeft.ref.current).selectAll(".lhsheaderlabel").data(["Women"]);
    const headerlhslabel = select(layout.headerLeft.ref.current)
      .selectAll(".lhsheaderlabel")
      .data([data[dataSetIndex].sides.left.name]);
    headerlhslabel
      .enter()
      .append("text")
      .attr("class", "lhsheaderlabel headerlabel lhs")
      .text((d) => d)
      .merge(headerlhslabel)
      .attr("x", layout.headerLeft.width)
      .attr("y", layout.headerLeft.height);
    headerlhslabel.exit().remove();

    const headerrhslabel = select(layout.headerRight.ref.current)
      .selectAll(".rhsheaderlabel")
      .data([data[dataSetIndex].sides.right.name]);
    headerrhslabel
      .enter()
      .append("text")
      .attr("class", "rhsheaderlabel headerlabel rhs")
      .text((d) => d)
      .attr("x", 0)
      .attr("y", layout.headerRight.height);
    headerrhslabel.exit().remove();

    const rhsbars = select(layout.plotRight.ref.current)
      .selectAll(".rhsbar")
      .data(data[dataSetIndex].sides.right.values);
    rhsbars
      .enter()
      .append("rect")
      .attr("class", "chartbar rhsbar")
      .attr("x", 0)
      .attr("y", (d, i) => i * (config.barsarea.bars.height + config.barsarea.bars.yspacing))
      .attr("height", config.barsarea.bars.height)
      .merge(rhsbars)
      .transition()
      .duration(config.timing.growduration)
      .attr("width", (d) => (zeroer ? d * zeroer * barXScaler : 2));
    rhsbars.exit().remove();

    const lhsbars = select(layout.plotLeft.ref.current).selectAll(".lhsbar").data(data[dataSetIndex].sides.left.values);
    lhsbars
      .enter()
      .append("rect")
      .attr("class", "chartbar lhsbar")
      .attr("x", layout.plotLeft.width)
      .attr("y", (d, i) => i * (config.barsarea.bars.height + config.barsarea.bars.yspacing))
      .attr("height", config.barsarea.bars.height)
      .merge(lhsbars)
      .transition()
      .duration(config.timing.growduration)
      .attr("x", (d) => layout.plotLeft.width - d * zeroer * barXScaler)
      .attr("width", (d) => (zeroer ? d * zeroer * barXScaler : 2));
    lhsbars.exit().remove();

    const centrelabels = select(layout.centre.ref.current).selectAll(".centrelabel").data(data[dataSetIndex].bands);
    centrelabels
      .enter()
      .append("text")
      .attr("class", "centrelabel")
      .text((d) => d)
      .attr("x", config.centreLabels.width / 2)
      .attr(
        "y",
        (d, i) => config.barsarea.bars.height / 2 + i * (config.barsarea.bars.height + config.barsarea.bars.yspacing)
      );

    //   .style("opacity", 0)
    //   .merge(centrelabels)
    //   // .transition()
    //   // .duration(400)
    //   // .style("opacity", 0)
    //   .transition()
    //   .duration(0)
    //   .transition()
    //   .duration(100)
    //   .style("opacity", 1);
    // centrelabels.exit().transition().duration(400).style("opacity", 0).remove();

    const rhsbarlabels = select(layout.plotRight.ref.current)
      .selectAll(".barlabelright")
      .data(data[dataSetIndex].sides.right.values);
    rhsbarlabels
      .enter()
      .append("text")
      .attr("class", "barlabelright")
      .text((d, i) => d + "%")
      .style("opacity", 0.1)
      .attr(
        "y",
        (d, i) => config.barsarea.bars.height / 2 + i * (config.barsarea.bars.height + config.barsarea.bars.yspacing)
      )
      .merge(rhsbarlabels)
      .transition()
      .duration(400)
      .style("opacity", 0)
      .transition()
      .duration(0)
      .attr("x", (d) => d * barXScaler + config.barsarea.labels.gaptobar)
      .transition()
      .duration(400)
      .style("opacity", zeroer);
    rhsbarlabels.exit().transition().duration(400).style("opacity", 0).remove();

    const lhsbarlabels = select(layout.plotLeft.ref.current)
      .selectAll(".barlabelleft")
      .data(data[dataSetIndex].sides.left.values);
    lhsbarlabels
      .enter()
      .append("text")
      .attr("class", (d, i) => "barlabelleft")
      .text((d, i) => d + "%")
      .style("opacity", 0.1)
      .attr("x", (d) => layout.plotLeft.width - config.barsarea.labels.gaptobar - d * barXScaler)
      .attr(
        "y",
        (d, i) => config.barsarea.bars.height / 2 + i * (config.barsarea.bars.height + config.barsarea.bars.yspacing)
      )
      .merge(lhsbarlabels)
      .transition()
      .duration(400)
      .style("opacity", 0)
      .transition()
      .duration(0)
      .attr("x", (d) => layout.plotLeft.width - config.barsarea.labels.gaptobar - d * barXScaler)
      .transition()
      .duration(400)
      .style("opacity", zeroer);
    lhsbarlabels.exit().transition().duration(400).style("opacity", 0).remove();
  }

  useEffect(() => {
    // INITIATION
    updateLayout();
    // SCREEN RESIZE
    const handleResize = debounce(() => {
      updateLayout();
    }, 400);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    updateLayout();
  }, [data]);

  useEffect(() => {
    drawChart();
  }, [zeroer]);

  useEffect(() => {
    repositionSvgGroups();
    drawChart();
    // colorSvgGroups();
  }, [layout]);

  return (
    <Waypoint bottomOffset="30%" onEnter={() => setZeroer(1)}>
      <div ref={svgContainerDivRef} className="doublesidedbarchart" style={{ width: "100%" }}>
        <svg ref={svgRef} style={{ width: "100%", height: 300 }}>
          <g ref={layout.plotLeft.ref} />
          <g ref={layout.plotRight.ref} />
          <g ref={layout.centre.ref} />
          <g ref={layout.headerLeft.ref} />
          <g ref={layout.headerRight.ref} />
        </svg>
      </div>
    </Waypoint>
  );
}
