import HeaderNav from "../organisms/HeaderNav";
import Footer from "../organisms/Footer";
// import Disciplines from "../organisms/Disciplines";
import H1H2block from "../organisms/H1H2Block";
import Tomnew from "../organisms/Tomnew";
import HeroRotator from "../organisms/HeroRotator";
import HeaderSub from "../organisms/HeaderSub";
import ChartsPanel from "../organisms/ChartsPanel";
import SingleDisciplinePanel from "../organisms/SingleDisciplinePanel";
//import TailwindSize from "../molecules/TailwindSize";
//import SingleTestimonial from "../organisms/SingleTestimonial";
import wireflow from "../../assets/diagrams/wireflow2.png";
import systemprocess from "../../assets/diagrams/systemprocess.png";
import datadashboard from "../../assets/diagrams/datadashboard.png";
const doubleSidedBarData = [
  {
    dataset: "Small Businesses",
    bands: ["None", "Basic", "Conversational", "Fluent", "Native/Bilingual"],
    sides: {
      left: {
        name: "English",
        values: [0.5, 0.9, 7, 37, 55],
      },
      right: {
        name: "Japanese",
        values: [5, 25, 46, 23, 5],
      },
    },
  },
  {
    dataset: "Large Businesses",
  },
];

export default function Homenew() {
  return (
    <>
      {/* <TailwindSize /> */}
      <HeaderNav />
      <HeaderSub />
      <HeroRotator />
      <Tomnew />
      {/* <SingleTestimonial
        text="Some quote about the marvellousness of Tom Coombs. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        person="Some Body"
        title="CEO, Quarrio"
      /> */}
      {/* <TestimonialCarousel testimonials={testimonials} /> */}
      {/* <Disciplines /> */}
      <SingleDisciplinePanel
        heading="Why I create Process Diagrams"
        // subtext="Working with stakeholders to document systems & processes and design their future direction"
        subtext={[
          "Documentation can be a time-drain, but documenting key systems and process is worth it. Every team I've worked with has discovered knowledge gaps and misalignment.",
          "The result can supercharge the team's ability to analyse and communicate.",
        ]}
        image={systemprocess}
        reverse={false}
        tint="bg-slate-100"
        alttext="Process Diagram"
      />
      {/* <SingleDisciplinePanel
        heading="My work with data"
        subtext={[
          "There are two challenges I often see companies facing. One is a lack of any data or certain types of data. There are often some easy wins available.",
          "The other challenge is having good data but struggling to extract actionable insights. Again there may be some easy wins, but usually it's a bigger team effort even requiring culture change.",
        ]}
        image={datadashboard}
        reverse={true}
        tint=""
        alttext="Data Dashboard"
      /> */}
      <ChartsPanel />

      <SingleDisciplinePanel
        heading="Types of systems I work on."
        subtext={[
          "As you probably guessed from the process diagrams etc. I mostly work for companies with process and data-heavy systems.",
        ]}
        image={wireflow}
        tint="bg-slate-100"
        alttext="UI flow diagram"
      />
      <H1H2block h1text="And that's it." h2text="You've reached the end ... or maybe the end of the beginning." />
      <Footer />
    </>
  );
}
