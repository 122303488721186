import { useState, useEffect } from "react";

export default function TextRotator({ items, speed }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(items.length - 1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % items.length);
    }, speed);
    setPreviousIndex(currentIndex === 0 ? items.length - 1 : currentIndex - 1);
    return () => clearInterval(intervalId);
  }, [currentIndex, items.length, speed]);

  const css = `
    .rotatorcontainer {
      width:100%;
      position: relative;
     }
    .rotator {
      position: absolute;
      width:100%;
      text-align: center !important;
    }
    .rotatorenter {
      top: 3rem;
      opacity: 1;
      transition: opacity 2s, transform 2s;
      transform: translateY(-3rem);
    }
    .rotatorexit {  
      top: 3rem;
      opacity: 0;
      transition: opacity 0.3s, transform 1s;
      transform: translateY(-5rem);
    }
    .rotatorwait { 
      visibility: hidden;
      opacity: 0;
    }
  `;

  return (
    <>
      <style>{css}</style>
      <div className="rotatorcontainer">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={
                "rotator characterfont " +
                (index === currentIndex ? "rotatorenter" : index === previousIndex ? "rotatorexit" : "rotatorwait")
              }
            >
              {item}
            </div>
          );
        })}
      </div>
    </>
  );
}
