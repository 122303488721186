import nokialogo from "../../assets/companylogos/NokiaLogo.png";
import hplogo from "../../assets/companylogos/HPLogo.png";
import xeroxlogo from "../../assets/companylogos/XeroxLogo.png";
import bologo from "../../assets/companylogos/BOLogo.png";
import gslogo from "../../assets/companylogos/GoldmanLogo.png";
import tomphoto from "../../assets/TomCoombs.png";
import { Waypoint } from "react-waypoint";
import { useState } from "react";
//style={{ transition-duration: `url(${image})` }}

function CompanyChit({ logo, alt, text, delay }) {
  const [isVisible, setIsVisible] = useState([false, false, false]);
  const handleEnterViewport = (index) => {
    setIsVisible((prevVisible) => {
      const newState = [...prevVisible];
      newState[index] = true;
      return newState;
    });
  };
  return (
    // <Waypoint bottomOffset="30%" onEnter={() => handleEnterViewport(0)}>
    <Waypoint onEnter={() => handleEnterViewport(0)}>
      <div
        className={` transition w-[12rem] lg:w-[10rem]     ${isVisible[0] ? "opacity-100" : "opacity-0"}`}
        style={{ transitionDelay: `${delay}ms`, transitionDuration: "1000ms" }}
      >
        <div className="flex flex-col items-center w-[12rem] lg:w-[10rem] ">
          <div className="w-[12rem] lg:w-[10rem] h-[6rem] rounded-lg bg-slate-700 flex items-center justify-center">
            <img className="w-[6rem]" src={logo} alt={alt} />
          </div>
          <p className="mt-4 text-white">{text}</p>
        </div>
      </div>
    </Waypoint>
  );
}

export default function Tomnew() {
  return (
    <section className="bg-slate-900 p-4 md:p-16 text-white">
      <div id="profileandcompanies" className="grid grid-row ">
        <div
          id="profilephototext"
          className="widthcontainer mx-auto grid grid-flow-row md:grid-flow-col gap-x-8 gap-y-8 m-16 "
        >
          <div id="profilephoto" className="sm:mx-auto">
            <img
              className="w-40 rounded-lg"
              src={tomphoto}
              alt="Headshot of Tom, devilishly handsome. Not really, that's a joke."
            />
          </div>
          <div id="profiletext" className="grid grid-row text-left ">
            <div id="profileheadertext" className="h-full border-l border-slate-500   border-solid ">
              <h1 className=" ml-4">I am a digital Product Management & User Experience expert. </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="widthcontainer  mx-auto  text-left">
        <p className="text-lg mt-4 mb-4">
          Companies hire me to manage digital products, conduct research, and create strategies/roadmaps. I'm often
          brought into companies to build Product/UX teams and help improve how they do UX/Product Management.
        </p>

        <h1 className="text-5xl mt-10 mb-4">Why me?</h1>
        <p className="text-lg">
          I’ve built Product Management and UX practices in corporate environments, startups and agencies. I know what
          works in each. Product Management is a balancing act. Varied experience helps ...
        </p>
        <div className="text-lg mt-6 mb-1 max-w-[80%] mx-auto">
          <em className="italic text-slate-400">
            <ul>
              <li className="mb-2">
                I cut my teeth in <em className="font-bold text-slate-100">technology</em> at Goldman Sachs in NY,
                working on trading system databases.
              </li>
              <li className="mb-2">
                At Xerox, I honed my craft of <em className="font-bold text-slate-100">UX research & design</em> and how
                to manage product development at a global and industrial scale.
              </li>
              <li className="mb-2">
                I spent 7 years in world-class design/brand agencies, creating
                <em className="font-bold text-slate-100"> product positioning & strategy</em> for global companies (HP,
                Avaya, Bang & Olufsen, Nokia).
              </li>
              <li className="mb-2">
                I’ve managed <em className="font-bold text-slate-100">startup business realities</em> as an
                entrepreneur, building a fintech consumer lending business.
              </li>
            </ul>
          </em>
        </div>
        <p className="text-lg mt-8 mb-2">
          My working style is analytical. I ask lots of questions. I can be unrelenting if I sense underlying issues or
          misalignment. I think discomfort is ok if it means we're making progress.
        </p>
        <p className="text-lg mt-2 mb-2">And anyway I’m nice about it. I'm even funny occasionally.</p>
        <p className="text-lg mt-2 mb-2">
          I'm also methodical, I generally push towards having processes for things, and documenting the complex parts
          of your business.
        </p>
        <p className="text-lg mt-2 mb-2">
          I am at my best consulting for companies that have core Product needs, but also where I can leverage my
          experience in agencies, startups and enterprises to build strategies, and operational excellence around UX and
          Product.
        </p>
        <div className="flex justify-center mt-12 mb-4">
          <div className="flex gap-2 flex-col lg:flex-row text-center items-center lg:items-start">
            <CompanyChit delay="0" logo={gslogo} text="Software / database engineering" alt="Goldman Sachs logo" />
            <CompanyChit delay="150" logo={hplogo} text="Digital programs strategy" alt="HP logo" />
            <CompanyChit delay="300" logo={xeroxlogo} text="UI design" alt="Xerox logo" />
            <CompanyChit delay="450" logo={nokialogo} text="Global website UX" alt="Nokia logo" />
            <CompanyChit delay="600" logo={bologo} text="UX Research" alt="Bang and Olufsen logo" />
          </div>
        </div>
      </div>
    </section>
  );
}
