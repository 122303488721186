// import TitleTextImageVertical from "./SingleDisciplinePanel";
// import widiagram from "../../assets/diagrams/widiagram.png";
// import loanflow from "../../assets/diagrams/LoanFlow.png";
// import loanflowsvg from "../../assets/diagrams/loanflow940.svg";
// import ui1 from "../../assets/diagrams/UI1.png";
// import processdiagram from "../../assets/diagrams/processdiagram.png";
// import dashboardlight from "../../assets/diagrams/Dashboardlight.png";
// import TextImageLRPair from "./TextImageLRPair";
import { Waypoint } from "react-waypoint";
import React, { useState } from "react";

export default function SingleDisciplinePanel({ heading, subtext, image, alttext, tint }) {
  const [isVisible, setIsVisible] = useState([false, false, false]);

  const handleEnterViewport = (index) => {
    setIsVisible((prevVisible) => {
      const newState = [...prevVisible];
      newState[index] = true;
      return newState;
    });
  };

  const handleExitViewport = (index) => {
    // setIsVisible((prevVisible) => {
    //   const newState = [...prevVisible];
    //   newState[index] = false;
    //   return newState;
    // });
  };
  return (
    <section className={"pt-20 pb-20 " + tint}>
      <div className="widthcontainer mx-auto text-center">
        {/* HEADERSUBTEXT */}
        <Waypoint bottomOffset="20%" onEnter={() => handleEnterViewport(0)} onLeave={() => handleExitViewport(0)}>
          <div className={`w-full transition duration-500   ${isVisible[0] ? "opacity-100" : "opacity-0"}`}>
            <h1 className="mt-12 mb-4">{heading}</h1>
          </div>
        </Waypoint>

        {/* SUBTEXT */}
        <Waypoint bottomOffset="20%" onEnter={() => handleEnterViewport(1)} onLeave={() => handleExitViewport(0)}>
          <div className={`w-full transition duration-500   ${isVisible[1] ? "opacity-100" : "opacity-0"}`}>
            <div>
              {subtext.map((para, index) => {
                return (
                  <p className="text-xl mt-8 mb-8 text-left" key={index}>
                    {para}
                  </p>
                );
              })}
            </div>
          </div>
        </Waypoint>
      </div>
      {/* IMAGE */}{" "}
      <Waypoint bottomOffset="20%" onEnter={() => handleEnterViewport(2)} onLeave={() => handleExitViewport(0)}>
        <div className={`w-full transition duration-500   ${isVisible[2] ? "opacity-100" : "opacity-0"}`}>
          <div className="widthcontainer mx-auto">
            <img className="shadow-lg mx-auto" src={image} alt={alttext} />
          </div>
        </div>
      </Waypoint>
    </section>
  );
}
