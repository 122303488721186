import logo256 from "../../assets/logo256.png";

export default function HeaderNav() {
  return (
    <div className="white-semi">
      <nav className=" border-gray-200 bg-slate-100 px-2 sm:px-4 py-2.5">
        <div className="flex justify-center items-center x-w-lg sm:max-w-4xl mx-auto">
          <img src={logo256} className="h-8 sm:h-12 mr-3" alt="Company Logo" />
        </div>
      </nav>
    </div>
  );
}
// import logo256 from "../../assets/logo256.png";

// export default function Nav() {
//   return (
//     <div className="white-semi">
//       <div className="container is-max-desktop  ">
//         <nav className="navbar   " role="navigation" aria-label="main navigation">
//           <div className="navbar-brand">
//             <a className="navbar-item" href="/unavw84pnw9uv4ancwcawncu">
//               <img src={logo256} height="140" />
//             </a>

//             <a
//               role="button"
//               className="navbar-burger"
//               aria-label="menu"
//               aria-expanded="false"
//               data-target="navbarBasicExample"
//             >
//               <span aria-hidden="true"></span>
//               <span aria-hidden="true"></span>
//               <span aria-hidden="true"></span>
//             </a>
//           </div>

//           <div id="navbarBasicExample" className="navbar-menu">
//             <div className="navbar-start">
//               {/* <a className="navbar-item" href="/unavw84pnw9uv4ancwcawncu">
//                 Home
//               </a> */}
//               {/* <a className="navbar-item" href="/work">
//                 Work
//               </a> */}
//             </div>

//             <div className="navbar-end">
//               <div className="navbar-item"></div>
//             </div>
//           </div>
//         </nav>
//       </div>
//     </div>
//   );
// }
