import "./App.css";
import { Routes, Route } from "react-router-dom";
import Homenew from "./components/pages/Homenew";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Homenew />} />
      </Routes>
    </div>
  );
}
