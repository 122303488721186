import { select, max, median, scaleLinear, axisTop, selectAll } from "d3";
import { useRef, useEffect, useState, useLayoutEffect } from "react";
import debounce from "lodash/debounce";
import "./RangeSliderChart.css";
import { Waypoint } from "react-waypoint";

export default function RangeSliderChart({ multidataset }) {
  const [layout, setLayout] = useState({});
  const [zeroer, setZeroer] = useState(0);
  const [datasetIndex, setDatasetIndex] = useState(0);
  const [data, setData] = useState(multidataset[datasetIndex].data);
  const [maxValue, setMaxValue] = useState(0);

  const config = {
    margin: 20,
    leftLabels: { width: 50, gutter: 20 },
    headerLabels: { height: 30, gutterBelow: 20 },
    plot: {
      row: { height: 30, spacing: 10 },
      centrepod: { height: 20, width: 30 },
      line: { stroke: 2 },
      lineends: { radius: 10 },
    },
    timing: {
      growduration: 500,
      delayspace: 100,
    },
  };

  const svgContainerDivRef = useRef(null);
  const svgRef = useRef(null);
  const headerLabelsRef = useRef(null);
  const leftLabelsRef = useRef(null);
  const plotAreaRef = useRef(null);

  function calculateLayout() {
    // setContainerWidth(svgContainerDivRef.current.clientWidth);
    let newValues = {};
    newValues.isSet = true;
    newValues.container = {
      width: svgContainerDivRef.current.clientWidth,
      height: svgContainerDivRef.current.clientHeight,
    };
    newValues.chartarea = {
      x: config.margin,
      y: config.margin,
      width: newValues.container.width - 2 * config.margin,
      height: newValues.container.height - 2 * config.margin,
    };
    newValues.headerLabels = {
      x: newValues.chartarea.x + config.leftLabels.width + config.leftLabels.gutter,
      y: newValues.chartarea.y,
      width: newValues.chartarea.width - config.leftLabels.width - config.leftLabels.gutter,
      height: config.headerLabels.height,
    };
    newValues.leftLabels = {
      x: newValues.chartarea.x,
      y: newValues.chartarea.y + config.headerLabels.height + config.headerLabels.gutterBelow,
      width: config.leftLabels.width,
      height:
        newValues.container.height - config.headerLabels.height - 2 * config.margin - config.headerLabels.gutterBelow,
    };
    newValues.plotArea = {
      x: newValues.headerLabels.x,
      y: newValues.leftLabels.y,
      width: newValues.headerLabels.width,
      height: newValues.leftLabels.height,
    };
    setLayout((layout) => ({
      ...layout,
      ...newValues,
    }));
  }
  function drawDebugShading() {
    selectAll(".shadingRect ").remove();
    select(svgRef.current)
      .append("rect")
      .attr("class", "shadingRect svgGroupShading")
      .attr("width", "100%") // svg does have a width
      .attr("height", "100%")
      .attr("fill", "black")
      .style("opacity", 0.1);
    select(headerLabelsRef.current)
      .append("rect")
      .attr("class", "shadingRect headerLabelsShading")
      // .attr("width", 10) // svg does have a width
      // .attr("height", 30)
      .attr("fill", "yellow")
      .style("opacity", 0.1);
    select(leftLabelsRef.current)
      .append("rect")
      .attr("class", "shadingRect leftLabelsShading")
      // .attr("width", 10) // svg does have a width
      // .attr("height", 30)
      .attr("fill", "orange")
      .style("opacity", 0.3);
    select(plotAreaRef.current)
      .append("rect")
      .attr("class", "shadingRect plotAreaShading")
      // .attr("width", 10) // svg does have a width
      // .attr("height", 30)
      .attr("fill", "blue")
      .style("opacity", 0.1);
    // SET WIDTH AND HEIGHT ON SHADING
    select(".headerLabelsShading").attr("width", layout.headerLabels.width).attr("height", layout.headerLabels.height);
    select(".leftLabelsShading").attr("width", layout.leftLabels.width).attr("height", layout.leftLabels.height);
    select(".plotAreaShading").attr("width", layout.plotArea.width).attr("height", layout.plotArea.height);
  }

  function positionSVGGroups() {
    // SET X & Y ON THE SVG GROUPS (they don't have width or height)
    select(headerLabelsRef.current).attr("transform", `translate(${layout.headerLabels.x}, ${layout.headerLabels.y})`); // move group but it has no width
    select(leftLabelsRef.current).attr("transform", `translate(${layout.leftLabels.x}, ${layout.leftLabels.y})`); // move group but it has no width
    select(plotAreaRef.current).attr("transform", `translate(${layout.plotArea.x}, ${layout.plotArea.y})`); // move group but it has no width
  }

  function drawChart() {
    const xScale = scaleLinear().domain([0, maxValue]).range([0, layout.plotArea.width]).nice();
    const topAxis = axisTop(xScale);

    // https://www.youtube.com/watch?v=UDDGcgxficY
    let topAxisG = select(headerLabelsRef.current).selectAll("g").data([null]);
    topAxisG = topAxisG
      .enter()
      .append("g")
      .attr("transform", `translate(0, ${layout.headerLabels.height})`)
      .merge(topAxisG)
      .attr("class", "topAxis");
    topAxisG.call(topAxis);

    const rangeLines = select(plotAreaRef.current).selectAll(".rangeLine").data(data);
    rangeLines
      .enter()
      .append("line")
      .attr("class", "rangeLine")
      .attr("x1", (d) => xScale(d.median))
      .attr("x2", (d) => xScale(d.median))
      .attr("y1", (d, i) => i * config.plot.row.height + config.plot.row.height / 2)
      .attr("y2", (d, i) => i * config.plot.row.height + config.plot.row.height / 2)
      .merge(rangeLines)
      .transition()
      .duration(config.timing.growduration)
      .delay((d, i) => i * config.timing.delayspace)
      .attr("x1", (d) => xScale(d.median) - zeroer * xScale(d.median - d.low))
      .attr("x2", (d) => xScale(d.median) + zeroer * xScale(d.high - d.median));
    rangeLines.exit().remove();

    const rangeLeftEnds = select(plotAreaRef.current).selectAll(".rangeLeftEnd").data(data);
    rangeLeftEnds
      .enter()
      .append("circle")
      .attr("class", "rangeEnd rangeLeftEnd")
      .attr("r", 3)
      .attr("cx", (d) => xScale(d.median))
      .attr("cy", (d, i) => i * config.plot.row.height + config.plot.row.height / 2)
      .style("opacity", zeroer)
      .merge(rangeLeftEnds)
      .transition()
      .duration(config.timing.growduration)
      .style("opacity", zeroer)
      .delay((d, i) => i * config.timing.delayspace)
      .attr("cx", (d) => xScale(d.median) - zeroer * xScale(d.median - d.low));
    rangeLeftEnds.exit().remove();

    const rangeRightEnds = select(plotAreaRef.current).selectAll(".rangeRightEnd").data(data);
    rangeRightEnds
      .enter()
      .append("circle")
      .attr("class", "rangeEnd rangeRightEnd")
      .attr("r", 3)
      .attr("cx", (d) => xScale(d.median))
      .attr("cy", (d, i) => i * config.plot.row.height + config.plot.row.height / 2)
      .style("opacity", zeroer)
      .merge(rangeRightEnds)
      .transition()
      .duration(config.timing.growduration)
      .style("opacity", zeroer)
      .delay((d, i) => i * config.timing.delayspace)
      .attr("cx", (d) => xScale(d.median) + zeroer * xScale(d.high - d.median));
    rangeRightEnds.exit().remove();

    const plotPods = select(plotAreaRef.current).selectAll(".plotPod").data(data);
    plotPods
      .enter()
      .append("rect")
      .attr("class", "plotPod")
      .attr("x", (d) => xScale(d.median))
      .attr("y", (d, i) => i * config.plot.row.height + config.plot.row.height / 2 - config.plot.centrepod.height / 2)
      .attr("height", config.plot.centrepod.height)
      .attr("width", 2)
      .attr("opacity", 0.1)
      .merge(plotPods)
      .transition()
      .duration(config.timing.growduration)
      .delay((d, i) => i * config.timing.delayspace)
      .attr("opacity", 1)
      .attr("x", (d) => xScale(d.median) - zeroer * 0.5 * config.plot.centrepod.width)
      .attr("width", zeroer ? zeroer * config.plot.centrepod.width : 2);
    plotPods.exit().remove();

    const leftlabels = select(leftLabelsRef.current).selectAll(".leftlabel").data(data);
    leftlabels
      .enter()
      .append("text")
      .attr("class", "leftlabel")
      .text((d) => d.category)
      .attr("x", layout.leftLabels.width)
      .attr("y", (d, i) => i * config.plot.row.height + config.plot.row.height / 2)
      .style("opacity", 1);
    // .merge(leftlabels)
    // .transition()
    // .duration(400)
    // .style("opacity", 0)
    // .transition()
    // .duration(0)
    // .attr("x", config.centregap / 2) // (d, i) => layout.panelwidth - config.bartolabelgaap - d * barXScaler)
    // .transition()
    // .delay((d, i) => config.delayspace * data.length) //i * config.delayspace + config.growduration)
    // .duration(400)
    // .style("opacity", 1);
    leftlabels.exit().transition().duration(400).style("opacity", 0).remove();
  }

  useEffect(() => {
    calculateLayout();

    // OUTER RESIZE LISTENER - MIGHT BE REDUNDANT
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === svgContainerDivRef.current) {
          calculateLayout();
        }
      });
    });
    resizeObserver.observe(svgContainerDivRef.current);

    // BROWSER RESIZE LISTENER
    const handleResize = debounce(calculateLayout, 100);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      select(".svgGroupShading").remove();
      select(".barlabel").remove();
      select(".headerlabel").remove();
    };
  }, []);

  useEffect(() => {
    const newDataValues = multidataset[datasetIndex].values;
    setData(newDataValues);
    const newDataMaxValue = newDataValues.reduce((max, { high }) => (high > max ? high : max), -Infinity);
    setMaxValue(newDataMaxValue);
  }, [multidataset, datasetIndex]);

  useEffect(() => {
    // this is separate to account for the asynchronous setting of data and maxvalue, but you might be as well to just pass them to drawChart etc
    if (layout.isSet) {
      drawChart();
      positionSVGGroups();
      // drawDebugShading();
    }
  }, [layout, data, maxValue, zeroer]); // data and maxvalue both use useState, therefore the set action is asynchronous, hence why they're both here

  return (
    <Waypoint bottomOffset="30%" onEnter={() => setZeroer(1)}>
      <div ref={svgContainerDivRef} className="rangesliderchart" style={{ width: "100%" }}>
        <svg ref={svgRef} style={{ width: "100%", height: 300 }}>
          <g ref={headerLabelsRef} />
          <g ref={leftLabelsRef} />
          <g ref={plotAreaRef} />
        </svg>
      </div>
    </Waypoint>
  );
}
