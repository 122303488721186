export default function Footer() {
  const thisyear = new Date().getFullYear();

  return (
    <section className="bg-slate-900 p-16 ">
      <div className="max-w-lg sm:max-w-4xl mx-auto text-center text-slate-300">
        <p className="text-md">&copy; {thisyear} All Rights Reserved</p>
        <p className="text-md">Registered in England and Wales 05246061</p>
      </div>
    </section>
  );
}
